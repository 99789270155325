import {combineReducers} from 'redux';
import auth, {authStateType} from './auth';
import account, {accountStateType} from './account';
import reviews, {reviewsStateType} from './reviews';
import deals, {dealsState} from './deals';
import settings, {settingsStateType} from './settings';
import favorites, {favoritesStateType} from './favorites';
import bookings, {bookingsStateType} from './bookings';
import popularDestinations, {popularDestinationsStateType} from './popularDestinations';
import search, {searchStateType} from './typeAheadSearch';
import hotelsSearch, {hotelsSearchStateType} from './hotelsSearch';
import hotel, {hotelStateType} from './hotel';
import vanRentals, {vanRentalsStateType} from './vanRentals';
import transfers, {transfersStateType} from './transfers';
// import mobilityRentals, {mobilityRentalsStateType} from './mobilityRentals';
import cityGuides, {cityGuidesStateType} from './cityGuides';
import checkout, {checkoutStateType} from './checkout';
import globalConfig, {globalConfigStateType} from './globalConfig';
import latestPosts, {latestPostsStateType} from './latestPosts';
import travelIdeas, {travelIdeasStateType} from './travelIdeas';
import contact, {contactUsStateType} from './contact';
import forumDiscourse, {discoursePayloadStateType} from './forumDiscourse';
import forumCircle, {circleForumStateType} from './circleForum';
import rentalCar, {rentalCarStateType} from './rentalCar';
import flights, {flightsStateType} from './flights';
import flightReview, {flightReviewStateType} from './flightReview';

export interface StateShape {
  globalConfig: globalConfigStateType;
  auth: authStateType;
  account: accountStateType;
  settings: settingsStateType;
  deals: dealsState;
  bookings: bookingsStateType;
  favorites: favoritesStateType;
  reviews: reviewsStateType;
  popularDestinations: popularDestinationsStateType;
  search: searchStateType;
  hotelsSearch: hotelsSearchStateType;
  hotel: hotelStateType;
  checkout: checkoutStateType;
  rentalCar: rentalCarStateType;
  vanRentals: vanRentalsStateType;
  transfers: transfersStateType;
  // mobilityRentals: mobilityRentalsStateType;
  cityGuides: cityGuidesStateType;
  flights: flightsStateType;
  flightReview: flightReviewStateType;
  latestPosts: latestPostsStateType;
  travelIdeas: travelIdeasStateType;
  contact: contactUsStateType;
  forumDiscourse: discoursePayloadStateType;
  forumCircle: circleForumStateType;
}

const rootReducer = combineReducers({
  globalConfig,
  auth,
  account,
  settings,
  deals,
  bookings,
  favorites,
  reviews,
  popularDestinations,
  search,
  hotelsSearch,
  hotel,
  checkout,
  rentalCar,
  vanRentals,
  transfers,
  // mobilityRentals,
  cityGuides,
  flights,
  flightReview,
  latestPosts,
  travelIdeas,
  contact,
  forumDiscourse,
  forumCircle,
});

export default rootReducer;

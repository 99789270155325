import {all, fork} from 'redux-saga/effects';

import * as auth from './auth';
import * as account from './account';
import * as favorites from './favorites';
import * as reviews from './reviews';
import * as deals from './deals';
import * as settings from './settings';
import * as bookings from './bookings';
import * as popularDestinations from './popularDestinations';
import * as search from './typeAheadSearch';
import * as hotelsSearch from './hotelsSearch';
import * as hotel from './hotel';
import * as checkout from './checkout';
import * as rentalCar from './rentalCar';
import * as vanRentals from './vanRentals';
import * as transfers from './transfers';
// import * as mobilityRentals from './mobilityRentals';
import * as cityGuides from './cityGuides';
import * as flights from './flights';
import * as airlineReview from './flightReview';
import * as latestPosts from './latestPosts';
import * as travelIdeas from './travelIdeas';
import * as contact from './contact';
import * as forumDiscourse from './forumDiscourse';
import * as forumCircle from './circleForum';

export default function* rootSaga() {
  yield all([
    fork(auth.signInWatcher),
    fork(auth.updateTokenWatcher),
    fork(auth.signOutWatcher),
    fork(auth.socialSignInWatcher),
    fork(auth.getUserDataWatcher),
    fork(auth.signUpWatcher),
    fork(auth.socialSignUpWatcher),
    fork(account.getPreferencesWatcher),
    fork(account.savePreferenceWatcher),
    fork(account.deletePreferenceWatcher),
    fork(account.getLocationWatcher),
    fork(account.saveLocationWatcher),
    fork(account.getBioWatcher),
    // fork(account.saveBioWatcher),
    fork(account.updateBioWatcher),
    fork(bookings.getBookingsWatcher),
    fork(bookings.getCurrentBookingWatcher),
    fork(bookings.getBookingConfirmationWatcher),
    fork(bookings.cancelBookingWatcher),
    fork(favorites.getFavoritesWatcher),
    fork(favorites.addFavoriteWatcher),
    fork(favorites.removeFavoriteWatcher),
    fork(reviews.getUserReviewsWatcher),
    fork(reviews.getAllReviewsWatcher),
    fork(reviews.getHotelDetailsWatcher),
    fork(reviews.addHotelReviewWatcher),
    fork(deals.getMembershipStatusWatcher),
    fork(deals.joinDealsWatcher),
    fork(deals.getOrganizationNameWatcher),
    fork(deals.activateOneTimeDiscountWatcher),
    fork(deals.activateReferralDiscountWatcher),
    fork(deals.activateGivebackParticipantWatcher),
    fork(deals.getUserOffersWatcher),
    fork(settings.getIsUserWatcher),
    fork(settings.changePasswordWatcher),
    fork(settings.getClientIPWatcher),
    fork(popularDestinations.getPopularDestinationsWatcher),
    fork(search.getSuggestionsWatcher),
    fork(search.getCitiesWatcher),
    fork(search.getAirportsWatcher),
    fork(search.getHotelsWatcher),
    fork(search.getPostsWatcher),
    fork(search.getArticlesWatcher),
    fork(hotelsSearch.getHotelsWatcher),
    fork(hotelsSearch.getAccessibilityDataWatcher),
    fork(hotel.getHotelDetailsWatcher),
    fork(hotel.getRoomsDataWatcher),
    fork(checkout.getBookingContractDataWatcher),
    fork(checkout.getRegularRateWatcher),
    fork(checkout.bookHotelWatcher),
    fork(rentalCar.getRentalCarWatcher),
    fork(vanRentals.rentVanWatcher),
    fork(transfers.bookTransferWatcher),
    // fork(mobilityRentals.rentVanWatcher),
    fork(cityGuides.getCitiesWatcher),
    fork(flights.flightsWatcher),
    fork(airlineReview.getAirportsWatcher),
    fork(latestPosts.getLatestPostsWatcher),
    fork(travelIdeas.getTravelIdeasWatcher),
    fork(contact.sendUserMessageWatcher),
    fork(forumDiscourse.getDiscoursePayloadWatcher),
    fork(forumCircle.circleSignInWatcher),
  ]);
}
